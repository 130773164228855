import {
    Field,
    PanelColumn
} from '@assets/component/Panel/PanelTable/PanelTable';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import * as style from '../PanelElements.module.less';
export class RowValue {
    static label: (
        field: Field
    ) => ReactElement<any, string | JSXElementConstructor<any>>;
    static sources(col: PanelColumn, data: any) {
        const items = [];
        if (!Array.isArray(data)) {
            data = [];
        }

        data.map((page: number, index: number) => {
            items.push(
                <div
                    key={page}
                    className={style.source_page_entry}
                    onClick={() => {
                        const store = useStore();
                        store.pdfImagePage = page;
                    }}
                >
                    {page}
                </div>
            );
        });
        return (
            <Flex alignItems="center" style={{ minWidth: 100 }} wrap="wrap">
                {items}
            </Flex>
        );
    }

    static percent(col: PanelColumn, data: any) {
        return (
            <Flex fillCell={true} alignItems="center">
                <div className={style.rowValue}>
                    <i>{data.toString()}</i>
                </div>
            </Flex>
        );
    }

    static value(col: PanelColumn, data: any) {
        return (
            <Flex fillCell={true} alignItems="center">
                <div className={style.rowValue}>{data.toString()}</div>
            </Flex>
        );
    }

    static boolean(col: PanelColumn, data: any) {
        const yes = <i className="bi bi-check"></i>;
        const no = <i className="bi bi-x"></i>;
        const unsure = <i className="bi bi-question"></i>;
        const icon =
            data === true || data === 1 ? yes : data === false ? no : unsure;
        return (
            <Flex fillCell={true} alignItems="center">
                <div className={style.rowValueBoolean}>{icon}</div>
            </Flex>
        );
    }
}
