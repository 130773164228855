import * as gstyle from '../../utils/global.module.less';
import { Panel } from '../Panel/Panel';
import { PanelHeader } from '../Panel/PanelHeader/PanelHeader';
import { Split } from '../Split/Split';
import { Flex } from '../UI/Flex/Flex';
import { ContentHeader } from './ContentHeader/ContentHeader';

import { observer } from 'mobx-react';
import { PanelCompanyOverview } from '../Panel/PanelCompanyOverview/PanelCompanyOverview';
import { PanelCompanyFinancials } from '../Financial/PanelCompanyYearly/PanelCompanyFinancials';
import store from '@assets/model/Store';
import { PanelImage } from '../Panel/PanelImage/PanelImage';
import { ScrollPanel } from '../Panel/ScrollPanel/ScrollPanel';
import { PanelIndustry } from '../Financial/PanelIndustry/PanelIndustry';
import { PanelReportNavigation } from '../Panel/PanelReportNavigation/PanelReportNavigation';

export const Content = observer(() => {
    if (!store.activeReport) {
        return null;
    }

    return (
        <Flex direction="column" style={{}}>
            <ContentHeader />
            <Split
                layoutPercent={[50, 50]}
                style={{ backgroundColor: 'white' }}
            >
                <Flex
                    direction="column"
                    className={gstyle.defaultPadding}
                    style={{ paddingRight: '10px' }}
                >
                    <ScrollPanel>
                        <PanelCompanyOverview />
                        <PanelCompanyFinancials />
                        <PanelIndustry />
                    </ScrollPanel>
                </Flex>

                <Panel className={gstyle.panelImage} shadow={true}>
                    {/* <PanelHeader title="PDF" /> */}
                    <PanelImage src={store.pdfImageUrl} />
                    <PanelReportNavigation />
                </Panel>
            </Split>
        </Flex>
    );
});
