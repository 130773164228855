import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Button, ButtonGroup } from 'react-bootstrap';
import * as style from './PanelReportNavigation.module.less';

export const PanelReportNavigation = observer(() => {
    const store = useStore();
    const maxPages = store.activeReport?.total_pdf_pages || 0;
    return (
        <ButtonGroup className={style.buttonGroup}>
            <Button
                className={style.buttonLeft}
                variant="secondary"
                onClick={() => {
                    store.pdfImagePage--;
                    if (store.pdfImagePage <= 0) {
                        store.pdfImagePage = maxPages;
                    }
                }}
            >
                Prev
            </Button>
            <Button variant="secondary" className={style.buttonMid}>
                Page {store.pdfImagePage}/{store.activeReport?.total_pdf_pages}
            </Button>
            <Button
                className={style.buttonRight}
                variant="secondary"
                onClick={() => {
                    store.pdfImagePage++;
                    if (store.pdfImagePage > maxPages) {
                        store.pdfImagePage = 1;
                    }
                }}
            >
                Next
            </Button>
        </ButtonGroup>
    );
});
