import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { Content } from '@assets/component/Content/Content';
import { Header } from '@assets/component/Header/Header';
import ModalContainer from '@assets/component/ModalContainer/ModalContainer';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../model/Translation';

const { t } = i18n;

const HomePage = observer(() => {
    const { id } = useParams();
    const store = useStore();
    useEffect(() => {
        if (id) {
            store.uiSearchTicker = id;
        }
    }, [id]);
    return (
        <>
            <ModalContainer />
            <div
                className="d-flex justify-content-center"
                style={{ height: '100%' }}
            >
                <Flex
                    direction="column"
                    className="h-100"
                    style={{ width: '95%' }}
                >
                    <Header />
                    <Content />
                </Flex>
            </div>
        </>
    );
});

export default withRouter(HomePage);
