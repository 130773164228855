import { head } from 'lodash';

export const PanelImage = (props: { src: string }) => {
    return (
        <img
            src={props.src}
            style={{
                height: '100%',
                maxHeight: '800px',
                minHeight: 'calc(50vh)',
                backgroundColor: '#f5f5f5',
                objectFit: 'contain',
                objectPosition: '50% 0%',
                borderTopRightRadius: '20px',
                borderTopLeftRadius: '20px'
            }}
        ></img>
    );
};
