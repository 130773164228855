.rowLabel {
    color: #102442;
    font-family: Hanken Grotesk;
    div[class*='title'] {
        font-weight: bold;
        font-size: 16px;
    }

    div[class*='desc'] {
        font-size: 13px;
    }
}

.columnHeader {
    color: #667085;
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-weight: bold;
}
.rowValue {
    color: #102442;
    font-family: Hanken Grotesk;
    font-size: 16px;
    font-weight: bold;
}

.rowValueBoolean {
    font-size: 32px;
    color: #102442;
}

.source_page_entry {
    min-width: 40px;
    height: 30px;
    background-color: #f2f4f7;
    color: #2075f4;
    font-size: 16px;
    font-weight: 500;
    padding: 2px 10px;
    border-radius: 25px;
    margin: 3px;
    text-align: center;
    transition: background-color 0.3s;
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: #d9d9d9 !important;
    }
    &:active {
        background-color: #b3b3b3 !important;
    }
}
