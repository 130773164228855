import { Form, Button, InputGroup } from 'react-bootstrap';
import * as style from './TickerSearch.module.less';
import { useBackend, useStore } from '@assets/model/Store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { observer } from 'mobx-react';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { useNavUtils } from '@assets/utils/NavUtils';

function filterBy(option, ticker) {
    if (ticker.selected.length) {
        return true;
    }

    if (!option.label) {
        return false;
    }

    return option.label.toLowerCase().indexOf(ticker.text.toLowerCase()) > -1;
}

export const TickerSearch = observer(() => {
    const store = useStore();
    const [currentTicker, setTickerValue] = useState('');
    const tickerRef = useRef('');
    const navUtils = useNavUtils();

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key == 'Enter') {
            }
        };

        addEventListener('keydown', onKeyDown);
        return () => {
            removeEventListener('keydown', onKeyDown);
        };
    }, []);
    var options = [];
    if (store.reportDataDB) {
        options = Object.keys(store.reportDataDB).map(ticker => {
            var companyName = store.reportDataDB[ticker].company_name;
            return {
                label: companyName,
                value: ticker
            };
        });
    }
    const sendRequest = (ticker: string) => {
        if (ticker == '') return;

        const fb = getFirebase();
        if (store.jobs[ticker]) {
            const status = store.jobs[ticker].status;
            if (status == 'pending') {
                return;
            }
        }

        const upper = ticker.toLocaleUpperCase();
        store.waitingForTickerToFinish = upper;
        fb.add_job(upper);
    };

    const refreshReportIcon = <i className="bi bi-arrow-repeat"></i>;
    const addIcon = <i className="bi bi-plus"></i>;
    const hourGlass = <i className="bi bi-hourglass"></i>;
    const alertIcon = <i className="bi bi-exclamation-triangle-fill"></i>;
    const ticker = currentTicker.toLocaleUpperCase();
    var icon = addIcon;
    if (store.jobs[ticker]) {
        const status = store.jobs[ticker].status;
        if (status == 'complete') {
            icon = refreshReportIcon;
        } else if (status == 'pending' || status == 'processing') {
            icon = hourGlass;
        } else {
            icon = alertIcon;
        }
    }
    return (
        <InputGroup className={style.tickerBox}>
            <InputGroup.Text className={style.leftEnd}>
                <i className="bi bi-search"></i>
            </InputGroup.Text>
            <Typeahead
                className={style.tickerSearch}
                id="ticker-search"
                filterBy={filterBy}
                options={options}
                highlightOnlyResult={true}
                placeholder="Search"
                onInputChange={text => {
                    setTickerValue(text);
                    tickerRef.current = text;
                }}
                onChange={selected => {
                    if (selected.length == 0) return;
                    const s = selected[0] as any;
                    store.uiSearchTicker = s.value;
                    store.waitingForTickerToFinish = '';
                    setTickerValue(s.value);
                    tickerRef.current = s.value;

                    navUtils.toTicker(s.value);
                }}
            ></Typeahead>
            {/* <InputGroup.Text
                className={style.rightEnd + ' ' + style.hoverButton}
                onClick={() => {
                    // sendRequest(tickerRef.current);
                }}
            >
                {icon}
            </InputGroup.Text> */}
        </InputGroup>
    );
});
