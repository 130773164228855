.panelEnd {
    height: 50px;
    background-color: red;
}

.buttonGroup {
    color: #667085 !important;
    font-family: Hanken Grotesk !important;
    font-weight: bold !important;
    font-size: 14px !important;
    border: none !important;
    border-collapse: collapse;
    background-color: transparent !important;
}

.buttonLeft {
    .buttonGroup();

    border-radius: 0 0 0 20px !important;
    background-color: #eaecf0 !important;
    &:hover {
        background-color: #d0d3da !important;
    }
}

.buttonRight {
    .buttonGroup();
    background-color: #eaecf0 !important;
    &:hover {
        background-color: #d0d3da !important;
    }

    border-radius: 0 0 20px 0 !important;
}

.buttonMid {
    .buttonGroup();
    background-color: #eaecf0 !important;
}
