import { Center } from '@assets/component/Center/Center';
import classNames from 'classnames';
import { ReactElement } from 'react';
import * as style from './PanelTable.module.less';

export type Field = {
    source: string;
    display: string;
    description?: string;
};

export type PanelColumn = {
    column: Field;
    element?: (col: PanelColumn, data: any) => ReactElement;
    header?: (col: PanelColumn) => ReactElement;
    overrideValue?: (field: Field, column: Field, cellData: any) => any;
    dontUseRowFormatting?: boolean;
};

export type PanelRow = {
    field: Field;
    label?: (field: Field) => ReactElement;
    element?: (col: PanelColumn, data: any) => ReactElement;
    formatter?: (data: any) => any;
    value: (field: Field, column: Field, table: any) => any;
};

interface IPanelTableProps {
    columns: PanelColumn[];
    rows: PanelRow[];
    data: any;
    disable_round_edges?: boolean;
}

export const PanelTable = (props: IPanelTableProps) => {
    const disableRoundEdges = props.disable_round_edges === true;

    const elementsToGridRow = (
        row: number,
        elements: ReactElement[],
        classesToAdd: string[] = [],
        rowPosition: 'start' | 'end' | 'none' = 'none'
    ) => {
        return elements.map((el, index) => {
            var cls = classNames(
                style.grid_item,
                style[`grid_row_${row}`],
                style[`grid_col_${index + 1}`],
                ...classesToAdd,
                style.grid__item,
                { [style.grid_col_start]: index === 0 },
                { [style.grid_col_end]: index === elements.length - 1 },
                { [style.grid_row_first]: rowPosition === 'start' },
                { [style.grid_row_last]: rowPosition === 'end' },
                { [style.disable_round_edges]: disableRoundEdges }
            );

            return (
                <div key={'Row_' + row + '_' + index} className={cls}>
                    {el}
                </div>
            );
        });
    };

    const rows = [];

    const headerLabels = [
        <div></div>,
        ...props.columns.map(col => {
            const el = col.header ? (
                col.header(col)
            ) : (
                <div>{col.column.display}</div>
            );

            return <div key={col.column.source}>{el}</div>;
        })
    ];
    rows.push(elementsToGridRow(1, headerLabels, [style.headerRow]));

    props.rows.forEach((row, index) => {
        const newRow = [
            <Center justifyContent="start">
                {row.label ? row.label(row.field) : row.field.display}
            </Center>
        ];

        props.columns.forEach((col, colIndex) => {
            let val = '-';
            const valFn = col.overrideValue || row.value;
            try {
                val = valFn(row.field, col.column, props.data);
                if (row.formatter && col.dontUseRowFormatting !== true) {
                    val = row.formatter(val);
                }
            } catch {
                val = '-';
            }

            const createEl = col.element || row.element;
            const newEl = (
                <Center key={colIndex} justifyContent="start">
                    {createEl(col, val)}
                </Center>
            );
            newRow.push(newEl);
        });

        const rowPosition =
            index === 0
                ? 'start'
                : index === props.rows.length - 1
                  ? 'end'
                  : 'none';
        rows.push(elementsToGridRow(rows.length + 1, newRow, [], rowPosition));
    });
    // props.columns.forEach((col, index) => {
    //     const v = col.value(col.field, col.column, props.data);

    //     const row = dataToRow(
    //         index + 2,
    //         data.map((d: any) => d.value)
    //     );
    //     rows.push(row);
    // });
    return <div className={style.gridContainer}>{rows}</div>;
};
