@title-color: #243a5c;
@side-padding: 20px;
@title-padding: 10px;

.panelImage {
    width: calc(100% - 20px) !important;
}

.defaultPadding {
    padding: @side-padding;
}

.shadow {
    box-shadow:
        0.2px 0.3px 1.4px rgba(0, 0, 0, 0.035),
        0.5px 0.8px 3.8px rgba(0, 0, 0, 0.05),
        1.2px 1.8px 9px rgba(0, 0, 0, 0.065),
        4px 6px 30px rgba(0, 0, 0, 0.1);
}

.dropShadow {
    filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.15));
}
