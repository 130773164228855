import { Backend } from '@assets/backend/backend';
import { ISerializeDoc } from '@assets/model/stores/StoreUtils';
import { useNavUtils } from '@assets/utils/NavUtils';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
    Auth,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    User
} from 'firebase/auth';
import {
    Database,
    getDatabase,
    onValue,
    ref,
    serverTimestamp,
    set
} from 'firebase/database';
import store, { useStore } from 'src/model/Store';

interface FileUpload {
    url: string;
    name: string;
    thumbnail: string;
}

class FirebaseAdapater {
    app: FirebaseApp;
    db: Database;
    userId: string;
    user: User = null;

    init() {
        const firebaseConfig = {
            apiKey: 'AIzaSyAvepjWXQW2-CcZO8rlT_5KizVrtcoxdLk',
            authDomain: 'cim-project-7b81c.firebaseapp.com',
            databaseURL:
                'https://cim-project-7b81c-default-rtdb.firebaseio.com',
            projectId: 'cim-project-7b81c',
            storageBucket: 'cim-project-7b81c.appspot.com',
            messagingSenderId: '289364347508',
            appId: '1:289364347508:web:e9c4d31793e4659e0a9f21'
        };
        // Initialize Firebase
        console.log('Initializing Firebase');
        this.app = initializeApp(firebaseConfig);
        this.db = getDatabase(this.app);
        var auth = getAuth(this.app);

        onAuthStateChanged(auth, user => {
            if (user) {
                console.log('Logged in!');
                this.userId = user.uid;
                this.user = user;

                const backend = new Backend();
                var r = ref(this.db, '/cim_results');
                onValue(r, data => {
                    const val = data.val();
                    //console.log(JSON.stringify(val));
                    const store = useStore();
                    store.reportDataDB = val;

                    const waitingFor = store.waitingForTickerToFinish;
                    if (waitingFor != '') {
                        if (val.hasOwnProperty(waitingFor)) {
                            location.href = '/' + waitingFor;
                            return;
                        }
                    }

                    if (store.uiSearchTicker == '') {
                        const keys = Object.keys(val);
                        if (keys.length > 0) {
                            store.uiSearchTicker = Object.keys(val)[0];
                        }
                    }
                });

                // var r = ref(this.db, '/jobs');
                // onValue(r, data => {
                //     store.jobs = data.val();
                // });

                // var activeJobs = ref(this.db, '/activeJob');
                // onValue(activeJobs, data => {
                //     store.activeJob = data.val();
                // });

                // var onlineStatus = ref(this.db, '/system_status');
                // onValue(onlineStatus, data => {
                //     store.systemStatus = data.val();
                // });
            } else {
                if (location.href.indexOf('login') < 0) {
                    location.href = '/login';
                }
            }
        });
    }

    add_job(tickerId: string) {
        const r = ref(this.db, `/jobs/${tickerId}`);
        const data = {
            tickerId: tickerId,
            user: getAuth().currentUser.email,
            status: 'pending',
            createdAt: serverTimestamp()
        };
        set(r, data);
    }
}

const mFirebaseService = new FirebaseAdapater();
export function getFirebase() {
    return mFirebaseService;
}
