import { CSSProperties } from 'react';

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type FlexJustifyContent = 'start' | 'end' | 'center' | 'between' | 'around';
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse';
type FlexAlignItems = 'start' | 'end' | 'center' | 'baseline' | 'stretch';

interface IFlexProps {
    children: React.ReactNode;
    direction?: FlexDirection;
    justify?: FlexJustifyContent;
    wrap?: FlexWrap;
    alignItems?: FlexAlignItems;
    className?: string;
    inline?: boolean;
    style?: CSSProperties | undefined;
    fillCell?: boolean;
}

export const Flex = (props: IFlexProps) => {
    const fillCell = props.fillCell || false;
    const direction = props.direction || 'row';
    const justify = props.justify || 'start';
    const wrap = props.wrap || 'nowrap';
    const alignItems = props.alignItems || 'stretch';
    const className = props.className || '';
    const inline = props.inline || false;
    const flexType = inline ? 'd-inline-flex' : 'd-flex';
    const classes = `${flexType} flex-${direction} justify-content-${justify} flex-${wrap} align-items-${alignItems} ${className} ${fillCell ? 'h-100 w-100' : ''}`;

    return (
        <div className={classes} style={props.style}>
            {props.children}
        </div>
    );
};
