import { TickerSearch } from '../TickerSearch/TickerSearch';
import { Flex } from '../UI/Flex/Flex';
import * as style from './Header.module.less';
import { Logo } from './Logo';

export const Header = () => {
    return (
        <Flex className={style.container}>
            <Logo />
            <div className={'flex-grow-1'} />
            <TickerSearch />
        </Flex>
    );
};
