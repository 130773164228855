export const System_Over_Overrides = {
	"Granicus-ConfidentialInformationPresentation(Fall2020)": {
		// "net_revenue_per_year": [{ "year": 2017, "revenue": 140 }, { "year": 2018, "revenue": 200 }],
		// "net_revenue_per_year_page_source": "10,11,23",
		// "hq_location": "Testing Place"
	},
	"MPI-ProjectMidasMP_122319": {
		"hq_location": " Cleveland Ohio",
		"year_founded": "1971"

	},
	"InsightCIM_vF": {
		"gross_profit_per_year": [{ "year": 2012, "gross_profit": 201.9 },
		{ "year": 2013, "gross_profit": 250.5 },
		{ "year": 2014, "gross_profit": 307.0 },
		{ "year": 2015, "gross_profit": 369.8 },
		{ "year": 2016, "gross_profit": 443.0 },
		{ "year": 2017, "gross_profit": 526.2 }],


		"ebitda_per_year": [{ "year": 2010, "ebitda": 46.4 },
		{ "year": 2011, "ebitda": 64.4 },
		{ "year": 2012, "ebitda": 82.4 },
		{ "year": 2013, "ebitda": 102.2 },
		{ "year": 2014, "ebitda": 125.2 },
		{ "year": 2015, "ebitda": 150.9 },
		{ "year": 2016, "ebitda": 180.7 }
		],
		"capex_per_year": [{ "year": 2010, "capex": 2.0 },
		{ "year": 2011, "capex": 2.2 },
		{ "year": 2012, "capex": 3.4 },
		{ "year": 2013, "capex": 4.2 },
		{ "year": 2014, "capex": 4.3 },
		{ "year": 2015, "capex": 5.2 },
		{ "year": 2016, "capex": 6.3 },
		{ "year": 2017, "capex": 7.5 }],
		"ebitda_cashflow_yearly": [{ "year": 2010, "ebitda_cashflow": 'N\\A' },
		{ "year": 2011, "ebitda_cashflow": 'N\\A' },
		{ "year": 2012, "ebitda_cashflow": 'N\\A' },
		{ "year": 2013, "ebitda_cashflow": 'N\\A' },
		{ "year": 2014, "ebitda_cashflow": 'N\\A' },
		{ "year": 2015, "ebitda_cashflow": 'N\\A' },
		{ "year": 2016, "ebitda_cashflow": 'N\\A' },
		{ "year": 2017, "ebitda_cashflow": 'N\\A' }],
		"ebitda_cashflow_yearly_page_source": "",
		"historical_market_growth": "15.0%",
		"historical_market_growth_page_source": "8",
		"adjusted_ebitda_per_year": [{ "year": 2010, "adjusted_ebitda": 46.4 }, { "year": 2011, "adjusted_ebitda": 64.4 }],

	}
}

/*
			field: {
				source: 'gross_profit_per_year',
				display: 'Gross Profit'
			},
			value: findByYear('gross_profit')

			*/
