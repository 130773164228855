//Get yearly value
//get Value
//default values
//Sources 

import { get_numbers } from '@assets/utils/Utils';
import { Field } from '../Panel/PanelTable/PanelTable';
import _ from 'lodash';

export class DataAccessUtils {
	static _instance: DataAccessUtils = null;
	constructor(private report: any) {
		//Kinda hacky this is because we pass callbacks maybe make better
		inst = this;
	}
	value(fieldName: string) {
		let data = inst.report;
		if (!data) return 'N/A';

		if (data.hasOwnProperty(fieldName)) {
			return data[fieldName];
		}

		return 'N/A';
	}


	actualYear() {
		const actualizedYears = inst.report['list_years_actualized_revenue'] ?? [];
		return Math.max(...actualizedYears);
	}

	valueByYear(fieldName: string, year: number, valueField: string, yearOffset: number = 0) {
		let data = inst.report;
		if (!data) {
			return 'N/A';
		}

		const yearSource = year + yearOffset;
		const dataRow = data[fieldName];

		if (!dataRow || !Array.isArray(dataRow)) {
			return 'N/A';
		}

		const r = dataRow.find((d: any) => d.year === yearSource);
		if (r == undefined) {
			return 'N/A';
		}

		if (r.hasOwnProperty(valueField)) {
			return r[valueField];
		} else {
			return 'N/A';
		}
	}

	sourcesByField(fieldName: string,) {
		let fields = [];

		if (fieldName === 'recurring_revenue_summary') {
			fields = [
				'subscription_revenue',
				'recurring_revenue_stream',
				'contractual_revenue'
			];
		} else {
			fields.push(fieldName);
		}

		const conv_source_to_num_array = (sourcesValue: any) => {
			var pages = get_numbers(sourcesValue.toString());
			return pages;
		};

		var sources = [];
		fields.forEach(fieldEntry => {
			let val = inst.value(fieldEntry + '_page_source');
			sources = sources.concat(
				conv_source_to_num_array(val)
			);
		});

		return sources;
	}

	sources(field: Field, column: Field, data: any) {
		let l = inst.sourcesByField(field.source);
		l = l.sort((a, b) => a - b);
		return _.uniq(l)
	}
}


var inst: DataAccessUtils = null;