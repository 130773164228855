import { Panel } from '@assets/component/Panel/Panel';
import { PanelHeader } from '@assets/component/Panel/PanelHeader/PanelHeader';
import {
    PanelColumn,
    PanelRow,
    PanelTable
} from '@assets/component/Panel/PanelTable/PanelTable';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { RowValue } from '../PanelCompanyYearly/PanelElements/RowValue/RowValue';
import {
    formatDollar,
    formatDollarCustom,
    formatPercent,
    formatPercentNoMultiply
} from '../PanelCompanyYearly/PanelElements/Formatters';
import { RowLabel } from '../PanelCompanyYearly/PanelElements/RowLable/RowLable';

export const PanelIndustry = observer(() => {
    const store = useStore();
    const data = store.activeReport;
    const query = store.query;

    if (!data) {
        return null;
    }

    const cols: PanelColumn[] = [
        {
            column: { source: null, display: 'Value' }
        },
        {
            column: { source: null, display: 'Source' },
            element: RowValue.sources,

            dontUseRowFormatting: true,
            overrideValue: query.sources
        }
    ];

    const genRow = (
        fieldName: string,
        display: string,
        formatter: (d: any) => string,
        extra?: any
    ) => {
        return {
            field: {
                source: fieldName,
                display: display
            },
            value: (_field, column, table) => {
                return query.value(fieldName);
            },
            formatter,
            label: RowLabel.percent_label,
            element: RowValue.percent,
            ...extra
        };
    };
    const rows: PanelRow[] = [
        genRow('market_share', 'Market Share', formatPercentNoMultiply),
        genRow(
            'historical_market_growth',
            'Historical Market Growth',
            formatPercentNoMultiply
        ),
        genRow(
            'projected_market_growth',
            'Projected Market Growth',
            formatPercentNoMultiply
        ),
        genRow(
            'total_addressable_market',
            'Total Addressable Market',
            formatDollarCustom(' Bil.'),
            {
                label: RowLabel.label,
                element: RowValue.value
            }
        )
    ];

    return (
        <Panel shadow={true}>
            <PanelHeader title="Industry" />
            <PanelTable columns={cols} rows={rows} data={data} />
        </Panel>
    );
});
