import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Table } from 'react-bootstrap';
import * as style from './ContentHeader.module.less';

import { Flex } from '../../UI/Flex/Flex';
import { MetadataEntry } from './MetadataEntry/MetadataEntry';
import { ScoreSummary } from './ScoreSummary/ScoreSummary';
import { Center } from '@assets/component/Center/Center';
export const ContentHeader = observer(() => {
    const store = useStore();
    const query = store.query;

    if (!query) {
        return null;
    }

    const error = store.errorMessageTicker;

    const metaFields = [
        { label: 'Industry:', value: query.value('company_main_market') },
        { label: 'Founded:', value: query.value('year_founded') },
        { label: 'CEO:', value: query.value('company_ceo') },
        { label: 'HQ:', value: query.value('hq_location') },
        { label: 'Last updated:', value: query.value('report_creation_date') }
    ];

    const values = [];
    metaFields.forEach((field, index) => {
        values.push(
            <MetadataEntry
                key={field.label}
                label={field.label}
                value={field.value}
            />
        );

        if (index < metaFields.length - 1) {
            values.push(
                <div
                    key={'sep_' + field.label}
                    className={style.seperatorFields}
                >
                    🞄
                </div>
            );
        }
    });

    // •︎ {store.ticker}
    return (
        <Flex direction="column" className={style.header}>
            <div className={style.title}>{store.company_name}</div>
            <Flex>{values}</Flex>
            <ScoreSummary />
        </Flex>
    );
});
