import { formatDollarStr, formatPercentStr } from '@assets/utils/Formatting';

export const formatDollarCustom = (postfix: string) => {
	return (data: any) => {
		return formatDollarStr(data.toString()) + postfix;
	}
}
export const formatDollar = (data: any) => {
	return formatDollarStr(data.toString());
};

export const formatPercent = (data: any) => {
	return formatPercentStr(data.toString());
};

export const formatPercentNoMultiply = (data: any) => {
	return formatPercentStr(data.toString(), 1);
};